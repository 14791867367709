<template>
  <div class="payment-evolution-container">
    <b-card class="payment-evolution-card">
      <div class="row-style">
        <d-body-nav-bar
            :tabs="[]"
            position="float-right"
            title="views.payment.content.custom-infos.payments-evolution.title"
        />
      </div>
      <b-row align="center" class="mt-3">
        <b-col cols="12">
          <Lines
              class="max-height-chart-line"
              :style="$store.getters['layout/getInnerWidth'] < 991 ? 'max-width: 95%': ''"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
export default{
  components: {
    Lines: () => import('./charts/Line'),
  }
}
</script>
<style scoped>
.payment-evolution-card .card-body {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 320px;
  max-height: 320px;
  padding: 1.25rem;
}

.payment-evolution-container .card {
  box-shadow: 0px 10px 16px #E2E1E6;
  border-radius: 8px;
}

.max-height-chart-line {
  max-height: 210px;
}
</style>